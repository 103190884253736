import React, { useState, useEffect } from "react"
import _ from 'lodash'
import { Card, Button, Pagination, PageItem } from "react-bootstrap"
import { Get } from '../../utils/axios'
import Loading from '../../component/Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Products = () => {
  const [activePage, setActivePage] = useState(0)
  const [productData, setProductData] = useState([])
  const [loading, setLoading] = useState( false )

  const getProduct = () => Get(
    `/getProduct`,
    payload => {
      let temp = _.remove( payload.data.list, { status: 'active'} )
      setProductData( temp )
    },
    () => toast("Error occured. Please refresh the page."),
    param => setLoading( param )
  )

  useEffect(() => {
    getProduct()
  }, [])

  return (
    <>
      <h2>All Products</h2>
      <div className="row w-100 at-product__container" style={{ maxWidth: 1400, margin: 'auto' }}>
        {productData
          .slice(12 * activePage, 12 * (activePage + 1))
          .map((item, index) => {
            return (
              <div className="col-md-6 col-xl-4 at-product__card">
                <Card style={{ width: "100%" }}>
                  <Card.Img
                    variant="top"
                    src={item.image_url[0] ? item.image_url[0] : 'assets/placeholder_1024x600.png'}
                    style={{ objectFit: "cover", height: 220 }}
                  />
                  <Card.Body>
                    <Card.Title>{item.product_name}</Card.Title>
                    <Card.Text>{item.product_description}</Card.Text>
                    <Card.Text>Size: <b>{item.product_volume}</b></Card.Text>
                    <Card.Text>Price: <b>RM { Number( item.retail_price ).toFixed(2)}</b></Card.Text>
                  </Card.Body>
                </Card>
              </div>
            )
          })}
      </div>
      {productData && productData.length > 12 && (
        <Pagination className="justify-content-center">
          <Pagination.First
            disabled={activePage === 0}
            onClick={() => {
              setActivePage(0)
            }}
          />
          <Pagination.Prev
            disabled={activePage === 0}
            onClick={() => {
              setActivePage(activePage - 1)
            }}
          />
          {[...Array(Math.ceil(productData.length/12))].map((item, index) => {
            return (
              <PageItem
                key={index + 1}
                active={index === activePage}
                onClick={() => {
                  setActivePage(index)
                }}
              >
                {index + 1}
              </PageItem>
            )
          })}
          <Pagination.Next
            disabled={activePage === Math.ceil(productData.length/12)-1}
            onClick={() => {
              setActivePage(activePage + 1)
            }}
          />
          <Pagination.Last
            disabled={activePage === Math.ceil(productData.length/12)-1}
            onClick={() => {
              setActivePage(Math.ceil(productData.length/12)-1)
            }}
          />
        </Pagination>
      )}
      { loading && <Loading/>}
      <ToastContainer />
    </>
  )
}

export default Products
