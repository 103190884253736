import React, { useState, useEffect, useRef } from "react"
import GoogleMapReact from "google-map-react"
import { Form } from "react-bootstrap"
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
} from "react-bootstrap"
import { Icon } from "@iconify/react"
import { Get } from "../../utils/axios"
import Loading from "../../component/Loading"
import { ToastContainer, toast } from "react-toastify"
import _ from "lodash"

const googleMapAPI = "AIzaSyAWJGXe8ITbUNDKKVMfmHzv8aQpR43qIT4"

const Distributors = () => {
  const mapRef = useRef(null)
  const [ center, setCenter ] = useState({ lat: 4.2105, lng: 101.9758 })
  const [ zoom, setZoom ] = useState( 7 )
  const [ data, setData ] = useState([])
  const [filterQuery, setFilterQuery] = useState({
    superLeader: true,
    leader: true,
    agent: true,
  })
  const [distributors, setDistributor] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchWord, setSearchWord] = useState("")

  const getDistributor = () =>
    Get(
      `/getDistributor`,
      (payload) => {
        let temp = _.filter(payload.data, { role: "Distributor" })
        temp = _.remove( temp, { status: 'active'} )
        setDistributor(temp)
        setData(temp)
      },
      () => toast("Error occured. Please refresh the page."),
      (param) => setLoading(param)
    )

  useEffect(() => {
    getDistributor()
  }, [])

  useEffect(() => {
    setData(
      distributors.filter((item) => {
        if (
          !filterQuery.superLeader &&
          !filterQuery.leader &&
          !filterQuery.agent
        )
          return true
        else {
          switch (item.position) {
            case "Super Leader":
              return filterQuery.superLeader
            case "Leader":
              return filterQuery.leader
            case "Agent":
              return filterQuery.agent
            default:
              return false
          }
        }
      })
    )
  }, [filterQuery, distributors])

  const LocationPin = ( props ) => {
    return (
      <>
        <div style={{ position: "absolute", transform: "translate(-50%, -50%)" }}>
          <Icon
            icon="entypo:location-pin"
            style={{
              fontSize: "50px",
              color:
              props.position === "Super Leader"
                  ? "#198754"
                  : props.position === "Leader"
                  ? "#0dcaf0"
                  : "ffc107",
            }}
          />
        </div>
        {
          props.showInfo && (
          <div className="map-info-window">
            <button className="map-info-close" onClick={() => props.closeInfo() }>
              <Icon
                icon="ant-design:close-circle-twotone"
                style={{ fontSize: "20px", color: "black" }}
              />
            </button>
            <p className="mb-0" style={{ fontWeight: 600 }}>{ props.name }</p>
            <p className="mb-0" style={{ fontSize: 10 }}>
              <Icon
                icon="ic:outline-whatsapp"
                style={{ fontSize: "15px", color: "grey", marginRight: 5 }}
              />
              { props.phone }
            </p>
            <p className="mb-0" style={{ fontSize: 10 }}>
              <Icon
                icon="bx:map-pin"
                style={{ fontSize: "15px", color: "grey", marginRight: 5 }}
              />
              { props.address }
            </p>
          </div>
          )
        }
      </>
    )
  }

  return (
    <>
      <h2>Distributors</h2>
      {!loading ? (
        <div className="row my-5 w-100" style={{ maxWidth: 1400, margin: 'auto' }}>
          <div className="col-md-4">
            <div className="row">
              <h6 style={{ textAlign: "left" }}>Filter:</h6>
            </div>
            {/* <div className="row mb-2">
              <ButtonToolbar style={{ justifyContent: "center" }}>
                <ButtonGroup className="me-2">
                  <Button
                    variant={filterQuery.superLeader ? "success" : "light"}
                    onClick={() =>
                      setFilterQuery({
                        ...filterQuery,
                        superLeader: !filterQuery.superLeader,
                      })
                    }
                  >
                    S.Leader
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="me-2">
                  <Button
                    variant={filterQuery.leader ? "info" : "light"}
                    onClick={() =>
                      setFilterQuery({
                        ...filterQuery,
                        leader: !filterQuery.leader,
                      })
                    }
                  >
                    Leader
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    variant={filterQuery.agent ? "warning" : "light"}
                    onClick={() =>
                      setFilterQuery({
                        ...filterQuery,
                        agent: !filterQuery.agent,
                      })
                    }
                  >
                    Agent
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div> */}
            <div className="mb-3 mx-2">
              <Form.Control
                type="text"
                value={searchWord}
                placeholder="Search By Location"
                onChange={(e) => {
                  setSearchWord(e.target.value)
                }}
              />
            </div>
            <div className="list-container">
            {
              _.filter(data, (e) => {
                return e.address
                  .toLowerCase()
                  .includes(searchWord.toLowerCase())
              }).map((distributor, index ) => {
                return (
                  <button
                    key={ index }
                    className="list-item" 
                    onClick={() => {
                      distributor.onShow = true
                      setZoom( 12 )
                      setCenter({ lat: distributor.location.latitude, lng: distributor.location.longitude })
                      if (mapRef && mapRef.current) {
                        window.scrollTo({ behavior: 'smooth', top: mapRef.current.offsetTop -50 })
                      }
                  }}>
                    <p className='mb-0'>
                      <Icon
                        icon="entypo:location-pin"
                        style={{ fontSize: "20px", color: "grey", marginRight: 10 }}
                      />
                      { distributor.username }
                    </p>
                    <p className='mb-0'>
                      <Icon
                        icon="bx:map-pin"
                        style={{ fontSize: "20px", color: "grey", marginRight: 10 }}
                      />
                      { distributor.address }</p>
                  </button>
                )
              })}
            </div>
          </div>
          <div ref={mapRef} className="col-md-8">
            <div style={{ height: "90vh", width: "98%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapAPI, language: "en" }}
                center={ center }
                zoom={ zoom }
                resetBoundsOnResize={true}
              >
                {
                  _.filter(data, (e) => {
                    return e.address
                      .toLowerCase()
                      .includes(searchWord.toLowerCase())
                  }).map( distributor => {
                    return (
                      <LocationPin
                        key={distributor.id}
                        lat={distributor.location.latitude}
                        lng={distributor.location.longitude}
                        name={distributor.username}
                        address={ distributor.address}
                        position={ distributor.position }
                        phone={ distributor.whatsapp }
                        closeInfo={ () => {
                          distributor.onShow = false
                          setCenter({ lat: 4.2105, lng: 101.9758 })
                          setZoom( 7 )
                        }}
                        showInfo={distributor.onShow}
                      />
                    )
                  })}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <ToastContainer />
    </>
  )
}

export default Distributors
