import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => {
  return(
    <div style={{
      minHeight: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
      }}>
      <ReactLoading type={ 'spin' } color={ '#fcc245' } height={80} width={80} />
    </div>
  )
}

export default Loading;