import React, { useState, useEffect } from "react"
import { Card, Button } from "react-bootstrap"
import { Get } from "../../utils/axios"
import Loading from "../../component/Loading"
import { ToastContainer, toast } from "react-toastify"
import _ from "lodash"

const Promotions = () => {
  const [promotionData, setPromotionData] = useState([])
  const [loading, setLoading] = useState(false)
  const getPromotion = () =>
    Get(
      `/getPromotion`,
      (payload) => {
        let tmpData = payload.data
        let tmpCategory = []
        tmpData.map((item) => {
          if (item.status.toLowerCase() === "active") {
            let tmp = _.find(tmpCategory, { category: item.category })
            if (tmp) {
              tmp.items.push(item)
            } else {
              let tmpItem = {
                category: item.category,
                items: [item],
              }
              tmpCategory.push(tmpItem)
            }
          }
        })
        setPromotionData(tmpCategory)
      },
      () => toast("Error occured. Please refresh the page."),
      (param) => setLoading(param)
    )

  useEffect(() => {
    getPromotion()
  }, [])
  return (
    <>
      <h2>Latest News</h2>
      {!loading ? (
        promotionData.map((promotion, index) => {
          return (
            <div className="row px-5 w-100" style={{ maxWidth: 1400, margin: 'auto' }}>
              <h2 style={{ textAlign: "left" }} className="px-5">
                {promotion.category}
              </h2>
              {promotion.items.map((item, index) => {
                return (
                  <div className="col-md-6 col-xl-4 p-5">
                    <Card className="w-100 h-100">
                      <Card.Img
                        variant="top"
                        src={item.image_url}
                        style={{ objectFit: "cover" }}
                        width={500}
                      />
                      <Card.Body>
                        <Card.Title>{item.promotion_title}</Card.Title>
                        <Card.Text>{item.promotion_description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                )
              })}
            </div>
          )
        })
      ) : (
        <Loading />
      )}
      <ToastContainer />
    </>
  )
}

export default Promotions
