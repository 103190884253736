import React, { useState, useEffect } from "react"
import Carousel from "react-bootstrap/Carousel"
import { Get } from "../../utils/axios"
import Loading from "../../component/Loading"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import _ from "lodash"
import './index.css'

const MainCarousel = () => {
  const [carouselData, setCarouselData] = useState([])
  const [loading, setLoading] = useState(false)

  const getCarousel = () =>
    Get(
      `/getPublicBanner`,
      (payload) => setCarouselData(payload.data),
      () => toast("Error occured. Please refresh the page."),
      (param) => setLoading(param)
    )

  useEffect(() => {
    getCarousel()
  }, [])

  return (
    <>
      <Carousel style={{ maxHeight: "600px" }}>
        {carouselData &&
          _.filter(carouselData, (e) => {
            return e.status.toLowerCase() === "active"
          }).map((carousel, index) => {
            return (
              <Carousel.Item
                interval={3000}
                style={{ 
                  marginStart: "auto", 
                  marginEnd: "auto"
                }}
              >
                <div className="at-carousel__bg" style={{ backgroundImage: `url(${ carousel.image_url })`,}}></div>
                <img
                  className="d-block w-100 at-carousel__conatiner"
                  src={carousel.image_url}
                  alt={`Slide ${index}`}
                />
                <Carousel.Caption
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    width: "100%",
                    right: 0,
                    left: 0,
                    bottom: 0,
                    paddingBottom: "5%",
                  }}
                >
                  <h3>{carousel.banner_title}</h3>
                  <p>{carousel.banner_description}</p>
                </Carousel.Caption>
              </Carousel.Item>
            )
          })}
      </Carousel>
      {loading && <Loading />}
      <ToastContainer />
    </>
  )
}

export default MainCarousel
