import React, { useState, useEffect, useRef } from "react"
import GoogleMapReact from "google-map-react"
import { Icon } from "@iconify/react"
import { Form } from "react-bootstrap"
import { Get } from "../../utils/axios"
import Loading from "../../component/Loading"
import { ToastContainer, toast } from "react-toastify"
import _ from "lodash"
import "react-toastify/dist/ReactToastify.css"

const googleMapAPI = "AIzaSyAWJGXe8ITbUNDKKVMfmHzv8aQpR43qIT4"

const Branches = () => {
  const mapRef = useRef(null)
  const [center, setCenter] = useState({ lat: 4.2105, lng: 101.9758 })
  const [zoom, setZoom] = useState(7)
  const [branches, setBranches] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchWord, setSearchWord] = useState("")

  const getBranches = () =>
    Get(
      `/getBranches`,
      (payload) => {
        let temp = _.remove( payload.data, { status: 'active'} )
        setBranches(temp)
      },
      () => toast("Error occured. Please refresh the page."),
      (param) => setLoading(param)
    )

  const LocationPin = (props) => {
    return (
      <>
        <div
          style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
        >
          <Icon
            icon="entypo:location-pin"
            style={{ fontSize: "50px", color: "red" }}
          />
        </div>
        {props.showInfo && (
          <div className="map-info-window">
            <button
              className="map-info-close"
              onClick={() => props.closeInfo()}
            >
              <Icon
                icon="ant-design:close-circle-twotone"
                style={{ fontSize: "20px", color: "black" }}
              />
            </button>
            <p className="mb-0" style={{ fontWeight: 600 }}>
              {props.name}
            </p>
            <p className="mb-0" style={{ fontSize: 10 }}>
              <Icon
                icon="bx:map-pin"
                style={{ fontSize: "15px", color: "grey", marginRight: 5 }}
              />
              {props.address}
            </p>
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    getBranches()
  }, [])

  return (
    <>
      <h2>Branches</h2>
      {!loading ? (
        <div
          className="row my-5 w-100"
          style={{ maxWidth: 1400, margin: "auto" }}
        >
          <div className="col-md-4">
            <div className="mb-3 mx-2">
              <Form.Control
                type="text"
                value={searchWord}
                placeholder="Search By Location"
                onChange={(e) => {
                  setSearchWord(e.target.value)
                }}
              />
            </div>
            <div className="list-container">
              {_.filter(branches, (e) => {
                  return e.address
                    .toLowerCase()
                    .includes(searchWord.toLowerCase())
                }).map((branch, index) => {
                return (
                  <button
                    className="list-item"
                    onClick={() => {
                      branch.onShow = true
                      setZoom(12)
                      setCenter({
                        lat: branch.location.latitude,
                        lng: branch.location.longitude,
                      })
                      if (mapRef && mapRef.current) {
                        window.scrollTo({ behavior: 'smooth', top: mapRef.current.offsetTop - 50})
                      }
                    }}
                  >
                    <p className="mb-0">
                      <Icon
                        icon="entypo:location-pin"
                        style={{
                          fontSize: "20px",
                          color: "grey",
                          marginRight: 10,
                        }}
                      />
                      {branch.username}
                    </p>
                    <p className="mb-0">
                      <Icon
                        icon="bx:map-pin"
                        style={{
                          fontSize: "20px",
                          color: "grey",
                          marginRight: 10,
                        }}
                      />
                      {branch.address}
                    </p>
                  </button>
                )
              })}
            </div>
          </div>
          <div ref={mapRef} className="col-md-8">
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapAPI, language: "en" }}
                center={center}
                zoom={zoom}
                resetBoundsOnResize={true}
              >
                {_.filter(branches, (e) => {
                  return e.address
                    .toLowerCase()
                    .includes(searchWord.toLowerCase())
                }).map((branch) => {
                  return (
                    <LocationPin
                      key={branch.id}
                      lat={branch.location.latitude}
                      lng={branch.location.longitude}
                      address={branch.address}
                      name={branch.username}
                      closeInfo={() => {
                        branch.onShow = false
                        setCenter({ lat: 4.2105, lng: 101.9758 })
                        setZoom(7)
                      }}
                      showInfo={branch.onShow}
                    />
                  )
                })}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <ToastContainer />
    </>
  )
}

export default Branches
