import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"

import "./styles.css"

const NavBar = (props) => {
  return (
    <Navbar bg="light" expand="lg" sticky="top" style={{ padding: "10px 0", marginBottom: 20 }}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ms-auto" activeKey={props.location.pathname}>
            <Nav.Link
              as={Link}
              to="/"
              eventKey="/"
              style={{ padding: "10px 20px" }}
            >
              Overview
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/promotion"
              eventKey="/promotion"
              style={{ padding: "10px 20px" }}
            >
              Latest News
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/products"
              eventKey="/products"
              style={{ padding: "10px 20px" }}
            >
              All Products
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/branches"
              eventKey="/branches"
              style={{ padding: "10px 20px" }}
            >
              Branches
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/retailers"
              eventKey="/retailers"
              style={{ padding: "10px 20px" }}
            >
              Retailers
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/distributors"
              eventKey="/distributors"
              style={{ padding: "10px 20px" }}
            >
              Distributors
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default withRouter(NavBar)
