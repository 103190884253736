import Axios from "axios"

const domain = "https://asia-southeast1-distributor-system-bc214.cloudfunctions.net"
// const domain = "http://localhost:5001/distributor-system-bc214/asia-southeast1"

export const Get = (url, response, error, load) => {
  load(true)
  return Axios.get(`${domain}${url}`)
    .then((res) => {
      if(res.status!==200){
        error(res.data.message)
      }
      else
        response(res)
      load(false)
    })
    .catch((err) => {
      error(err)
      load(false)
    })
}

export const Post = (url, data, response, error, load) => {
  load(true)
  return Axios.post(`${domain}${url}`, data)
  .then((res) => {
    if(res.status!==200){
      error(res.data.message)
    }
    else
      response(res)
    load(false)
  })
  .catch((err) => {
    error(err)
    load(false)
  })
}