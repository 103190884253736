import React, { useEffect, useState } from "react"
import Shop1 from './shop1.jpeg'
import Shop2 from './shop2.jpeg'
import Shop3 from './shop3.jpeg'
import Concept1 from './concept1.jpeg'
import Concept2 from './concept2.jpeg'
import About1 from './about1.jpeg'
import { ToastContainer, toast } from "react-toastify"
import Loading from "../../component/Loading"
import { Get } from "../../utils/axios"
import './index.css'

const CompanyBackground = () => {
  const [loading, setLoading] = useState(false)
  const [overview, setOverview] = useState({
    introduction:"",
    concept_design:"",
    concept_design_01:"",
    concept_design_02:"",
    about_us:"",
    mission:"",
    vision:""
  })
  const getOverview = () =>
    Get(
      `/getOverview`,
      (payload) => {
        let tmpData = payload.data
        setOverview( tmpData )
      },
      () => toast.error("Error occured. Please refresh the page."),
      (param) => setLoading(param)
    )

  useEffect(() => {
    getOverview()
  }, [])

  return(
    <>
      {
        !loading && (
          <div className="at-company-background" style={{minHeight:"80vh"}}>
            <h1>Company Background</h1>
            <div className="introduction_section">
              <div className="introduction_bg"></div>
              <div className='content'>
                <h2 style={{ marginTop: 10 }}>Introduction</h2>
                <hr/>
                <div 
                  className="row" 
                  style={{ 
                    margin: '5px auto',
                    maxWidth: 1020
                  }}>
                  <p className="col-md-6">{ overview.introduction }</p>
                  <div className="col-md-6 d-flex">
                    <div className="col-6">
                      <img className="intro1" src={ Shop2 } alt={ 'shop2' } />
                    </div>
                    <div classname='col-6'>
                      <img className="intro2" src={ Shop1 } alt={ 'shop1' } />
                      <img className="intro2" src={ Shop3 } alt={ 'shop3' } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="concept_section">
              <div className='row' style={{ maxWidth: 1020, margin: 'auto' }}>
                <div className="col-md-6">
                  <h2>Concept Design</h2>
                  <hr/>
                  <p>{ overview.concept_design }</p>
                </div>
                <div className="col-md-6">
                  <img className="concept1" src={ Concept1 } alt={ 'Concept1' } />
                  <div className="concept1_shadow"></div>
                    <div style={{
                      width: 250,
                      height: 333.33,
                    }}></div>
                </div>
              </div>
              <div className="row mt-5" style={{ maxWidth: 1020, margin: 'auto' }}>
                <div className="col-md-6">
                  <img className="concept2" src={ Concept2 } alt={ 'Concept2' } />
                  <div className="concept2_shadow"></div>
                    <div style={{
                      width: 250,
                      height: 333.33,
                    }}></div>
                </div>
                <div className="col-md-6">
                  <p style={{ display: 'flex', padding: 15 }}><span style={{ fontSize: 50 }}>01.</span>{ overview.concept_design_01 }</p>
                  <p style={{ display: 'flex', padding: 15 }}><span style={{ fontSize: 50 }}>02.</span>{ overview.concept_design_02 }</p>
                </div>
              </div>
            </div>
            <div className="aboutUs_section">
              <div className="row" style={{ maxWidth: 1020, margin: 'auto' }}>
                <div className="col-md-6">
                  <img style={{ width: '100%',padding:50 }} src={ About1 } alt={ 'About1' } />
                </div>
                <div className="col-md-6">
                  <h2 style={{ color: 'white' }}>About Us</h2>
                  <hr/>
                  <p>{ overview.about_us }</p>
                </div>
              </div>
              <div style={{ maxWidth: 1020, margin: 'auto' }}>
                <p><span>Vision <hr/></span>{ overview.vision }</p>
                <p><span>Mission <hr/></span>{ overview.mission }</p>
              </div>
            </div>
          </div>
        )
      }
      { loading && <Loading />}
    </>
  )
}

export default CompanyBackground