import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import { useEffect } from "react"
import moment from "moment"
import "bootstrap/dist/css/bootstrap.min.css"

import "./App.css"
import MainCarousel from "./component/Carousel"
import NavBar from "./component/NavBar"
import Footer from './component/Footer'
import Promotions from "./container/Promotions"
import Products from "./container/Products"
import Distributors from "./container/Distributors"
import Retailers from "./container/Retailers"
import Branches from "./container/Branches"
import CompanyBackground from "./container/CompanyBackground"
import { Post } from "./utils/axios"

function App() {
  useEffect(()=>{
    Post(
      `/publicVisit`,
      { date: moment( new Date() ).format( "YYYY-MM-DD" ) },
      () => {},
      () => {},
      () => {}
    )
  }, [])

  return (
    <div className="App">
      <Router>
        <img 
          className="App-logo"
          src={ 'assets/logo.png' } 
          alt={ 'logo' } />
        <MainCarousel />
        <NavBar />
        <Switch>
          <Route path="/" exact>
            <CompanyBackground />
          </Route>
          <Route path="/promotion" exact>
            <Promotions />
          </Route>
          <Route path="/products" exact>
            <Products />
          </Route>
          <Route path="/retailers" exact>
            <Retailers />
          </Route>
          <Route path="/distributors" exact>
            <Distributors />
          </Route>
          <Route path="/branches" exact>
            <Branches />
          </Route>
          <Redirect to="/"></Redirect>
        </Switch>
        <Footer/>
      </Router>
    </div>
  )
}

export default App
