import React from "react"

import './styles.css'

const Footer = () => {
  return(
    <div style={{ backgroundColor: '#f8f9fa', padding: '50px 50px 10px 50px' }}>
      <h5 style={{ marginBottom: 50}}>Get your ice cream now!</h5>
      <p style={{ marginBottom: 10, fontWeight: 500, fontSize: 14 }}>Appetit Village HQ E-Commerce Platforms (Klang Valley Areas):</p>
      <div className="row" style={{ maxWidth: 1020, margin: 'auto' }}>
        <a className="col-md-6 at-footer__logo_container" href={ 'https://www.airasia.com/food/my/en/restaurant/appetit-village' } target="_blank" rel="noreferrer">
          <img style={{ width: 200 }} src={ 'https://www.theborneopost.com/newsimages/2021/11/bp29-sk-aa-p1.png' } alt='airasia' />
          <p>AirAsiaFood</p>
        </a>
        <a className="col-md-6 at-footer__logo_container" href={ 'https://food.grab.com/my/en/restaurant/appetit-village-saujana-impian-delivery/1-C3BZA751SCAHEE' } target="_blank" rel="noreferrer">
          <img style={{ width: 200 }} src={ 'https://cdn.kibrispdr.org/data/104/download-logo-grabfood-png-44.png' } alt='grabfood' />
          <p>GrabFood</p>
        </a>
      </div>
      <h5>Social Media</h5>
      <p style={{ marginBottom: 50}}>Find out more from our social media.</p>
      <div className="row" style={{ maxWidth: 1020, margin: 'auto' }}>
        <div className='col-md-4'></div>
        <p  className="col-md-4" style={{ margin: 'auto', fontSize: 12 }}>© ALL RIGHTS RESERVED</p>
        <div className="col-md-4">
          <a className="d-flex at-footer__logo_container mb-1" style={{ width: 200, margin: 'auto' }} href={ 'https://www.facebook.com/appetit.icecream' } target="_blank" rel="noreferrer">
            <img style={{ width: 20 }} src={ 'https://pnggrid.com/wp-content/uploads/2021/07/Facebook-Logo-Square-768x768.png' } alt='facebook' />
            <p style={{ textAlign: 'start', paddingLeft: 5 }}>Appetit Village HQ</p>
          </a>
          <a className="d-flex at-footer__logo_container mb-1" style={{ width: 200, margin: 'auto' }} href={ 'https://www.instagram.com/appetitvillage_hq/' } target="_blank" rel="noreferrer">
            <img style={{ width: 20 }} src={ 'http://assets.stickpng.com/images/580b57fcd9996e24bc43c521.png' } alt='instagram' />
            <p style={{ textAlign: 'start', paddingLeft: 4 }}>@appetitvillage_hq</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer;